var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-list animatedBox"},[_c('div',{staticClass:"container fluid"},[_c('filter-panel',{attrs:{"useToggle":"","isLoading":_vm.isFilterLoading,"loadingItems":1},on:{"filter":_vm.filterData,"clear":_vm.clearFilter},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"p-2"},[_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Status","options":_vm.statusOptions,"optionLabel":function (val) { return val.name; },"optionValue":function (val) { return val.id; }},model:{value:(_vm.filter['status:id']),callback:function ($$v) {_vm.$set(_vm.filter, 'status:id', $$v)},expression:"filter['status:id']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.bookingTableColumns,"rows":_vm.bookingTableData,"totalRows":_vm.bookingTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toBookingDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'currentStatus')?_c('span',[_c('subsale-purchase-status',{attrs:{"status":props.row.status}}),(_vm.getIsOTPExpired(props.row) && _vm.isBookingActive(props.row))?_c('chip',{staticClass:"bg-danger fg-white ml-1"},[_vm._v(" OTP Expired ")]):_vm._e()],1):_vm._e(),(props.column.field == 'propertySummary')?_c('property-summary',{attrs:{"property":props.row.property,"address":""}}):(props.column.field == 'OTPPrice')?_c('span',{staticClass:"font-bold"},[_vm._v(" RM "+_vm._s(_vm.numberWithCommas( parseInt(props.row.offerToPurchase.purchasePriceNum) ))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no subsale booking"}})],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }